import { Button, Stack, Typography } from '@mui/material';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from '@apollo/client';
import { Controller, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  recoverManagerPasswordDocument,
  resendManagerVerificationEmailDocument,
} from '@social-garden/api/documents/manager.ts';
import {
  RecoverPasswordFieldValues,
  RecoverPasswordSchema,
} from '../../constants/ValidationSchema.ts';
import CodeField from '../../components/CodeField.tsx';
import NewPasswordField from '../../components/NewPasswordField.tsx';
import ConfirmPasswordField from '../../components/ConfirmPasswordField.tsx';
import ReadOnlyEmailField from '../../components/ReadOnlyEmailField.tsx';

export default function RecoverPassword() {
  const navigate = useNavigate();
  const { t } = useTranslation(['manager']);
  const [searchParams] = useSearchParams();
  const [recoverManagerPassword, { loading: recoverManagerPasswordLoading }] =
    useMutation(recoverManagerPasswordDocument);
  const [
    resendManagerVerificationEmail,
    { loading: resendManagerVerificationEmailLoading },
  ] = useMutation(resendManagerVerificationEmailDocument);
  const {
    control,
    formState: { isValid },
    handleSubmit,
  } = useForm<RecoverPasswordFieldValues>({
    resolver: zodResolver(RecoverPasswordSchema),
    mode: 'all',
    defaultValues: {
      email: searchParams.get('email') || undefined,
      code: searchParams.get('code') || undefined,
    },
  });

  const handleOnResendCode = useCallback(async () => {
    const email = searchParams.get('email');
    if (email === null) {
      return;
    }
    await resendManagerVerificationEmail({
      variables: {
        input: {
          email,
        },
      },
    });
  }, [searchParams, resendManagerVerificationEmail]);

  const handleOnValid = useCallback(
    async ({ email, code, password }: RecoverPasswordFieldValues) => {
      const result = await recoverManagerPassword({
        variables: {
          input: {
            email,
            code,
            password,
          },
        },
      });
      if (result?.data?.recoverManagerPassword) {
        navigate('/login');
      }
    },
    [recoverManagerPassword, navigate],
  );

  return (
    <Stack spacing={4}>
      <Stack spacing={1} alignItems="center">
        <Typography variant="h5" align="center">
          {t('recoverPassword.header')}
        </Typography>
      </Stack>
      <Stack
        component="form"
        spacing={2}
        noValidate
        onSubmit={handleSubmit(handleOnValid)}>
        <Controller<RecoverPasswordFieldValues, 'email'>
          name="email"
          control={control}
          render={ReadOnlyEmailField}
        />
        <Controller<RecoverPasswordFieldValues, 'code'>
          name="code"
          control={control}
          render={CodeField}
        />
        <Controller<RecoverPasswordFieldValues, 'password'>
          name="password"
          control={control}
          render={NewPasswordField}
        />
        <Controller<RecoverPasswordFieldValues, 'passwordConfirm'>
          name="passwordConfirm"
          control={control}
          render={ConfirmPasswordField}
        />
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={!isValid || recoverManagerPasswordLoading}>
          {t('recoverPassword.submitButton')}
        </Button>
        <Button
          variant="outlined"
          disabled={resendManagerVerificationEmailLoading}
          onClick={handleOnResendCode}>
          {t('recoverPassword.resendCodeButton')}
        </Button>
      </Stack>
    </Stack>
  );
}
