import { Chip, Unstable_Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface Statistics {
  applications: number;
  applicationsAccepted: number;
  submissions: number;
  submissionsAccepted: number;
}

interface CampaignStatisticsProps {
  statistics: Statistics;
}

export default function CampaignStatistics({
  statistics,
}: CampaignStatisticsProps) {
  const { t } = useTranslation(['manager']);

  return (
    <Grid container spacing={1}>
      <Grid container xs={12}>
        <Grid xs="auto">
          <Chip
            label={t('campaign.statistics.applications', {
              count: statistics.applications,
            })}
            color="applied"
            variant="filled"
            size="small"
          />
        </Grid>
        <Grid xs="auto">
          <Chip
            label={t('campaign.statistics.applicationsAccepted', {
              count: statistics.applicationsAccepted,
            })}
            color="submissionPending"
            variant="filled"
            size="small"
          />
        </Grid>
      </Grid>
      <Grid container xs={12}>
        <Grid xs="auto">
          <Chip
            label={t('campaign.statistics.submissions', {
              count: statistics.submissions,
            })}
            color="submitted"
            variant="filled"
            size="small"
          />
        </Grid>
        <Grid xs="auto">
          <Chip
            label={t('campaign.statistics.submissionsAccepted', {
              count: statistics.submissionsAccepted,
            })}
            color="submissionAccepted"
            variant="filled"
            size="small"
          />
        </Grid>
      </Grid>
    </Grid>
  );
}
