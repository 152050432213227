import { Stack, StackProps, Typography } from '@mui/material';

interface ChannelStatisticItemProps extends StackProps {
  label: string;
  value: string | number;
}

export default function ChannelStatisticItem({
  label,
  value,
  ...rest
}: ChannelStatisticItemProps) {
  return (
    <Stack minWidth={100} alignItems="center" {...rest}>
      <Typography fontWeight={500} textAlign="center">
        {value}
      </Typography>
      <Typography variant="body2" color="text.secondary" textAlign="center">
        {label}
      </Typography>
    </Stack>
  );
}
