import InstagramChannelStatistics, {
  InstagramChannelStatisticsData,
} from './InstagramChannelStatistics.tsx';
import TiktokChannelStatistics, {
  TiktokChannelStatisticsData,
} from './TiktokChannelStatistics.tsx';
import YoutubeChannelStatistics, {
  YoutubeChannelStatisticsData,
} from './YoutubeChannelStatistics.tsx';

export interface InstagramChannelStatistics
  extends InstagramChannelStatisticsData {
  __typename: 'InstagramChannel';
}

export interface TiktokChannelStatistics extends TiktokChannelStatisticsData {
  __typename: 'TiktokChannel';
}

export interface YoutubeChannelStatistics extends YoutubeChannelStatisticsData {
  __typename: 'YoutubeChannel';
}

export type ChannelStatisticsData =
  | InstagramChannelStatistics
  | TiktokChannelStatistics
  | YoutubeChannelStatistics;

interface ChannelStatisticsProps {
  data: ChannelStatisticsData;
  variant?: 'simple' | 'complex';
}

export default function ChannelStatistics({
  data,
  variant,
}: ChannelStatisticsProps) {
  switch (data.__typename) {
    case 'InstagramChannel':
      return <InstagramChannelStatistics data={data} variant={variant} />;
    case 'TiktokChannel':
      return <TiktokChannelStatistics data={data} variant={variant} />;
    case 'YoutubeChannel':
      return <YoutubeChannelStatistics data={data} variant={variant} />;
  }
}
