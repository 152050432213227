import { PieChart, PieSeriesType, PieValueType } from '@mui/x-charts';
import { Stack, Typography, Unstable_Grid2 as Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';

export interface InstagramChannelInsightsData {
  ageFollowerDemographics: {
    id: string;
    age: string;
    percentage: number;
  }[];
  countryFollowerDemographics: {
    id: string;
    country: string;
    percentage: number;
  }[];
  genderFollowerDemographics: {
    id: string;
    gender: string;
    percentage: number;
  }[];
}

interface InstagramChannelInsightsProps {
  data: InstagramChannelInsightsData;
  height?: number;
}

type MakeOptional<T, K extends keyof T> = Omit<T, K> & Partial<Pick<T, K>>;

const DEFAULT_PIE_SERIES: MakeOptional<
  PieSeriesType<MakeOptional<PieValueType, 'id'>>,
  'type' | 'data'
> = {
  highlightScope: { fade: 'global', highlight: 'item' },
  faded: {
    innerRadius: 30,
    additionalRadius: -30,
    color: 'gray',
  },
};

export default function InstagramChannelInsights({
  data,
  height = 250,
}: InstagramChannelInsightsProps) {
  const { t } = useTranslation(['common', 'manager']);

  const countryData = useMemo(() => {
    const sum = data.countryFollowerDemographics
      .map(({ percentage }) => percentage)
      .reduce((accumulator, currentValue) => accumulator + currentValue);

    const cd = data.countryFollowerDemographics.map(
      ({ id, country, percentage }) => ({
        id,
        label: country,
        value: percentage,
      }),
    );
    cd.push({
      id: 'other-countries',
      value: 100 - sum,
      label: t('common:other'),
    });
    return cd;
  }, [data.countryFollowerDemographics, t]);

  return (
    <Grid container spacing={2}>
      <Grid xs={12} md={4}>
        <Stack spacing={0.5}>
          <Typography variant="subtitle1" fontWeight="500">
            {t('manager:channelInsights.ageFollowerDemographics.header')}
          </Typography>
          <PieChart
            series={[
              {
                ...DEFAULT_PIE_SERIES,
                data: data.ageFollowerDemographics.map(
                  ({ id, age, percentage }) => ({
                    id,
                    label: age,
                    value: percentage,
                  }),
                ),
              },
            ]}
            height={height}
          />
        </Stack>
      </Grid>
      <Grid xs={12} md={4}>
        <Stack spacing={0.5}>
          <Typography variant="subtitle1" fontWeight="500">
            {t('manager:channelInsights.countryFollowerDemographics.header')}
          </Typography>
          <PieChart
            series={[
              {
                ...DEFAULT_PIE_SERIES,
                data: countryData,
              },
            ]}
            height={height}
          />
        </Stack>
      </Grid>
      <Grid xs={12} md={4}>
        <Stack spacing={0.5}>
          <Typography variant="subtitle1" fontWeight="500">
            {t('manager:channelInsights.genderFollowerDemographics.header')}
          </Typography>
          <PieChart
            series={[
              {
                ...DEFAULT_PIE_SERIES,
                data: data.genderFollowerDemographics.map(
                  ({ id, gender, percentage }) => ({
                    id,
                    label: gender,
                    value: percentage,
                  }),
                ),
              },
            ]}
            height={height}
          />
        </Stack>
      </Grid>
    </Grid>
  );
}
