import { Outlet } from 'react-router-dom';
import { Unstable_Grid2 as Grid, Paper, Stack } from '@mui/material';
import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import SuspenseFallback from '../components/SuspenseFallback.tsx';
import Logo from '../components/Logo.tsx';
import InfluencerLeaning from '../assets/influencer-leaning.jpg';
import Copyright from '../components/Copyright.tsx';
import PageTitle from '../components/PageTitle.tsx';

export default function AuthLayout() {
  const { t } = useTranslation(['manager']);

  return (
    <Grid flex={1} container>
      <Grid
        xs={false}
        sm={false}
        md={5}
        lg={6}
        sx={{
          zIndex: -1,
          position: 'fixed',
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          backgroundImage: () => `url(${InfluencerLeaning})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundColor: (theme) => theme.palette.background.paper,
        }}>
        <Stack
          sx={{
            backdropFilter: 'brightness(35%) blur(3px)',
            color: (theme) => theme.palette.common.white,
          }}
          flex={1}
          p={4}
          spacing={4}
          alignItems="center"
          justifyContent="center">
          <PageTitle align="center">{t('authLayout.title')}</PageTitle>
        </Stack>
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          flex: 1,
          backgroundColor: (theme) => theme.palette.background.default,
        }}
        xs={12}
        sm={12}
        md={7}
        lg={6}
        mdOffset={5}
        lgOffset={6}
        component={Paper}
        elevation={6}
        square>
        <Stack
          flex={1}
          p={4}
          maxWidth={800}
          mx="auto"
          spacing={4}
          justifyContent="center">
          <Stack alignItems="center">
            <Logo />
          </Stack>
          <Suspense fallback={<SuspenseFallback />}>
            <Outlet />
          </Suspense>
          <Stack alignItems="center">
            <Copyright align="center" />
          </Stack>
        </Stack>
      </Grid>
    </Grid>
  );
}
