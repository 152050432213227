import { useCallback, useState } from 'react';
import {
  Button,
  Container,
  FormControl,
  InputLabel,
  SelectChangeEvent,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CampaignStatus } from '@social-garden/utils/types.ts';
import { useQuery } from '@apollo/client';
import { faMemoPad } from '@fortawesome/pro-thin-svg-icons/faMemoPad';
import { campaignsDocument } from '@social-garden/api/documents/campaign.ts';
import BrandSelect from '../../components/BrandSelect.tsx';
import PageTitle from '../../components/PageTitle.tsx';
import GridList from '../../components/GridList.tsx';
import CampaignStatusSelect from '../../components/CampaignStatusSelect.tsx';
import SuspenseFallback from '../../components/SuspenseFallback.tsx';
import CampaignListItem, {
  CampaignListItemData,
} from '../../components/CampaignListItem.tsx';
import EmptyState from '../../components/EmptyState.tsx';

export default function Campaigns() {
  const { t } = useTranslation(['common', 'manager']);
  const [brandIds, setBrandIds] = useState<string[]>([]);
  const [status, setStatus] = useState<CampaignStatus[]>([]);

  const { data, loading, fetchMore } = useQuery(campaignsDocument, {
    variables: {
      input: {
        brandIds,
        status,
      },
      first: 25,
      page: 1,
    },
  });

  const handleOnBrandChange = useCallback(
    (event: SelectChangeEvent<string[]>) => {
      const selected = event.target.value;
      if (Array.isArray(selected)) {
        setBrandIds(selected);
      }
    },
    [],
  );

  const handleOnStatusChange = useCallback(
    (event: SelectChangeEvent<CampaignStatus[]>) => {
      const selected = event.target.value;
      if (Array.isArray(selected)) {
        setStatus(selected);
      }
    },
    [],
  );

  const renderItem = useCallback(
    (campaignData: CampaignListItemData) => (
      <Grid key={campaignData.id} xs={12} md={4}>
        <CampaignListItem variant="outlined" data={campaignData} />
      </Grid>
    ),
    [],
  );

  return (
    <Container
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 4,
      }}>
      <Grid container spacing={4} alignItems="center">
        <Grid xs={12} md>
          <PageTitle>{t('manager:navigation.campaigns')}</PageTitle>
        </Grid>
        <Grid xs={12} md="auto">
          <Button
            variant="contained"
            size="large"
            color="secondary"
            href="/create-campaign">
            {t('manager:campaign.create.newButton')}
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="filter-brand-label">
              {t('manager:campaign.filter.brand.label')}
            </InputLabel>
            <BrandSelect
              variant="outlined"
              labelId="filter-brand-label"
              label={t('manager:campaign.filter.brand.label')}
              value={brandIds}
              multiple
              onChange={handleOnBrandChange}
            />
          </FormControl>
        </Grid>
        <Grid xs={12} md={4}>
          <FormControl fullWidth>
            <InputLabel id="filter-status-label">
              {t('manager:campaign.filter.status.label')}
            </InputLabel>
            <CampaignStatusSelect
              labelId="filter-status-label"
              label={t('manager:campaign.filter.status.label')}
              value={status}
              multiple
              variant="outlined"
              onChange={handleOnStatusChange}
            />
          </FormControl>
        </Grid>
      </Grid>
      <Grid container spacing={4}>
        <Grid xs={12}>
          {data ? (
            <GridList<CampaignListItemData>
              container
              spacing={4}
              paginator={data.campaigns}
              fetchMore={fetchMore}
              renderItem={renderItem}
              loading={loading}
              ListEmptyComponent={
                <EmptyState
                  icon={faMemoPad}
                  text={t('manager:campaign.campaignList.empty')}
                />
              }
            />
          ) : (
            <SuspenseFallback minHeight={300} />
          )}
        </Grid>
      </Grid>
    </Container>
  );
}
