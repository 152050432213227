import { Unstable_Grid2 as Grid, Link, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  abbreviateCount,
  formatPercentage,
} from '@social-garden/utils/helpers.ts';
import ChannelStatisticItem from './ChannelStatisticItem.tsx';

export interface YoutubeChannelStatisticsData {
  title: string;
  url: string;
  subscriberCount: number;
  videoCount: number;
  averageEngagementRate?: number | null;
  averageReach?: number | null;
}

interface YoutubeChannelStatisticsProps {
  data: YoutubeChannelStatisticsData;
  variant?: 'simple' | 'complex';
}

export default function YoutubeChannelStatistics({
  data,
  variant = 'complex',
}: YoutubeChannelStatisticsProps) {
  const { t } = useTranslation(['common']);

  return (
    <Stack spacing={0.25}>
      {variant === 'complex' ? (
        <Stack direction="row">
          <Link href={data.url} target="_blank">
            {data.title}
          </Link>
        </Stack>
      ) : null}
      <Grid container spacing={1}>
        <Grid xs="auto">
          <ChannelStatisticItem
            label={t('common:channel.subscriberCount', {
              count: data.subscriberCount,
            })}
            value={abbreviateCount(data.subscriberCount)}
          />
        </Grid>
        <Grid xs="auto">
          <ChannelStatisticItem
            label={t('common:channel.averageEngagementRate')}
            value={formatPercentage(data.averageEngagementRate || 0)}
          />
        </Grid>
        <Grid xs="auto">
          <ChannelStatisticItem
            label={t('common:channel.averageReach')}
            value={formatPercentage(data.averageReach || 0)}
          />
        </Grid>
        <Grid xs="auto">
          <ChannelStatisticItem
            label={t('common:channel.videoCount', {
              count: data.videoCount,
            })}
            value={abbreviateCount(data.videoCount)}
          />
        </Grid>
      </Grid>
    </Stack>
  );
}
