import { BrowserClient, feedbackIntegration, getClient } from '@sentry/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/pro-regular-svg-icons/faBullhorn';
import { IconButton, IconButtonProps } from '@mui/material';
import { useCallback } from 'react';

export default function FeedbackButton({
  sx,
  style,
  size,
  color,
}: Pick<IconButtonProps, 'sx' | 'style' | 'size' | 'color'>) {
  const client = getClient<BrowserClient>();
  const feedback =
    client?.getIntegrationByName<ReturnType<typeof feedbackIntegration>>(
      'Feedback',
    );

  const handleOnClick = useCallback(() => {
    if (feedback) {
      feedback.openDialog();
    }
  }, [feedback]);

  // Don't render custom feedback button if Feedback integration isn't installed
  if (!feedback) {
    return null;
  }

  return (
    <IconButton
      sx={sx}
      style={style}
      size={size}
      color={color}
      aria-label="Feedback button"
      onClick={handleOnClick}>
      <FontAwesomeIcon icon={faBullhorn} />
    </IconButton>
  );
}
