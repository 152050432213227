import { useTranslation } from 'react-i18next';
import { Link, Stack } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  convertCentToEuro,
  formatPercentage,
} from '@social-garden/utils/helpers.ts';
import ReportStatisticCard from '../../components/ReportStatisticCard.tsx';
import CampaignReportToolbar from './CampaignReportToolbar.tsx';

interface InstagramPostCampaignReportData {
  id: string;
  completedCount: number;
  followerCount: number;
  views: number;
  saves: number;
  engagementRate?: number | null;
  cpv?: number | null;
  price: number;
  creatorHasCampaignReports: {
    id: string;
    creatorHasCampaign: {
      id: string;
      creator: {
        username: string;
      };
    };
    followerCount: number;
    views: number;
    saves: number;
    engagementRate?: number | null;
    cpv?: number | null;
    price: number;
  }[];
}

interface InstagramPostCampaignReportProps {
  data: InstagramPostCampaignReportData;
}

export default function InstagramPostCampaignReport({
  data,
}: InstagramPostCampaignReportProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['common']);

  return (
    <Stack spacing={4}>
      <Stack sx={{ gap: 2 }} direction="row" flexWrap="wrap">
        <ReportStatisticCard
          label={t('common:report.completed')}
          value={data.completedCount.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.followers')}
          value={data.followerCount.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.views')}
          value={data.views.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.saves')}
          value={data.saves.toLocaleString(resolvedLanguage)}
        />
        <ReportStatisticCard
          label={t('common:report.engagementRate')}
          value={
            data.engagementRate ? formatPercentage(data.engagementRate) : 'n/a'
          }
        />
        <ReportStatisticCard
          label={t('common:report.cpv')}
          value={
            data.cpv ? convertCentToEuro(data.cpv, resolvedLanguage) : 'n/a'
          }
        />
        <ReportStatisticCard
          label={t('common:report.price')}
          value={convertCentToEuro(data.price, resolvedLanguage)}
        />
      </Stack>
      <DataGrid
        rows={data.creatorHasCampaignReports}
        columns={[
          {
            flex: 1,
            minWidth: 150,
            field: 'creatorUsername',
            headerName: t('common:username'),
            valueGetter: (_, row) => row.creatorHasCampaign.creator.username,
            renderCell: ({ row }) => (
              <Link href={`/creator-has-campaign/${row.creatorHasCampaign.id}`}>
                {row.creatorHasCampaign.creator.username}
              </Link>
            ),
          },
          {
            width: 120,
            field: 'followerCount',
            headerName: t('common:report.followers'),
            valueGetter: (_, row) =>
              row.followerCount.toLocaleString(resolvedLanguage),
          },
          {
            width: 120,
            field: 'views',
            headerName: t('common:report.views'),
            valueGetter: (_, row) => row.views.toLocaleString(resolvedLanguage),
          },
          {
            width: 120,
            field: 'saves',
            headerName: t('common:report.saves'),
            valueGetter: (_, row) => row.saves.toLocaleString(resolvedLanguage),
          },
          {
            width: 150,
            field: 'engagementRate',
            headerName: t('common:report.engagementRate'),
            valueGetter: (_, row) =>
              row.engagementRate ? formatPercentage(row.engagementRate) : 'n/a',
          },
          {
            width: 120,
            field: 'cpv',
            headerName: t('common:report.cpv'),
            valueGetter: (_, row) =>
              row.cpv ? convertCentToEuro(row.cpv, resolvedLanguage) : 'n/a',
          },
          {
            width: 120,
            field: 'price',
            headerName: t('common:report.price'),
            valueGetter: (_, row) =>
              convertCentToEuro(row.price, resolvedLanguage),
          },
        ]}
        disableColumnSorting={false}
        slots={{
          toolbar: CampaignReportToolbar,
          pagination: null,
        }}
      />
    </Stack>
  );
}
