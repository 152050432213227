import { Container, Unstable_Grid2 as Grid } from '@mui/material';
import { useSuspenseQuery } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { managerDocument } from '@social-garden/api/documents/manager.ts';
import UpdatePassword from './UpdatePassword.tsx';
import RequestNewEmail from './RequestNewEmail.tsx';
import DeleteAccount from './DeleteAccount.tsx';
import ErrorBoundary from '../../components/ErrorBoundary.tsx';
import PageTitle from '../../components/PageTitle.tsx';

export default function Profile() {
  const { t } = useTranslation(['manager']);
  const { data } = useSuspenseQuery(managerDocument);

  if (data.whoAmI.__typename !== 'Manager') {
    return <ErrorBoundary />;
  }

  return (
    <Container>
      <Grid container spacing={4}>
        <Grid xs={12}>
          <PageTitle>{t('manager:profile.title')}</PageTitle>
        </Grid>
        <Grid xs={12} md={6}>
          <UpdatePassword />
        </Grid>
        <Grid xs={12} md={6}>
          <RequestNewEmail currentEmail={data.whoAmI.email} />
        </Grid>
        <Grid xs={12} md={6}>
          <DeleteAccount
            isLastManager={data.whoAmI.company.managers.length === 1}
          />
        </Grid>
      </Grid>
    </Container>
  );
}
