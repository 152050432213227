export interface Download {
  uri: string;
  hash?: string | null;
}

export enum CreatorHasCampaignStatus {
  APPLIED = 'APPLIED',
  SUBMISSION_PENDING = 'SUBMISSION_PENDING',
  SUBMITTED = 'SUBMITTED',
  SUBMISSION_ACCEPTED = 'SUBMISSION_ACCEPTED',
  REPORTING_COMPLETED = 'REPORTING_COMPLETED',
  REPORTING_FAILED = 'REPORTING_FAILED',
  DECLINED = 'DECLINED',
  FAILED = 'FAILED',
  CANCELLED = 'CANCELLED',
}

export enum CampaignGiftType {
  CODE = 'CODE',
  PARCEL = 'PARCEL',
}

export interface CampaignGift {
  id: string;
  name: string;
  description: string;
  type: CampaignGiftType;
}

export enum ChannelType {
  INSTAGRAM = 'INSTAGRAM',
  TIKTOK = 'TIKTOK',
  YOUTUBE = 'YOUTUBE',
}

export interface InstagramChannel {
  __typename: 'InstagramChannel';
  averageEngagementRate?: number | null;
  followersCount: number;
  id: string;
  url: string;
  username: string;
  mediaCount: number;
}

export interface TiktokChannel {
  __typename: 'TiktokChannel';
  averageEngagementRate?: number | null;
  averageReach?: number | null;
  displayName: string;
  followerCount: number;
  id: string;
  url: string;
  videoCount: number;
}

export interface YoutubeChannel {
  __typename: 'YoutubeChannel';
  averageEngagementRate?: number | null;
  averageReach?: number | null;
  id: string;
  subscriberCount: number;
  title: string;
  url: string;
  videoCount: number;
}

export type Channelable = InstagramChannel | TiktokChannel | YoutubeChannel;

export type ChannelTypename = Pick<Channelable, '__typename'>['__typename'];

export interface Channel {
  id: string;
  channelableType: ChannelType;
  channelable: Channelable;
}

export enum SocialLoginProvider {
  APPLE = 'APPLE',
  FACEBOOK = 'FACEBOOK',
  GOOGLE = 'GOOGLE',
}

export interface ExpoUpdate {
  type: 'EXPO';
}

export interface AppStoreUpdate {
  type: 'APP_STORE';
  version: string;
}

export type Update = ExpoUpdate | AppStoreUpdate;
export type UpdateProvider = 'EXPO' | 'APP_STORE';

export enum CreatorNotificationType {
  CAMPAIGN_APPLICATION_ACCEPTED = 'CAMPAIGN_APPLICATION_ACCEPTED',
  CAMPAIGN_APPLICATION_DECLINED = 'CAMPAIGN_APPLICATION_DECLINED',
  CAMPAIGN_GOAL_SUBMISSION_ACCEPTED = 'CAMPAIGN_GOAL_SUBMISSION_ACCEPTED',
  CAMPAIGN_GOAL_SUBMISSION_FAILED = 'CAMPAIGN_GOAL_SUBMISSION_FAILED',
  CAMPAIGN_GOAL_SUBMISSION_REMINDER = 'CAMPAIGN_GOAL_SUBMISSION_REMINDER',
  CAMPAIGN_GOAL_SUBMISSION_RETRIED = 'CAMPAIGN_GOAL_SUBMISSION_RETRIED',
  CREATOR_HAS_CAMPAIGN_CANCELLED = 'CREATOR_HAS_CAMPAIGN_CANCELLED',
  NEW_CAMPAIGN_AVAILABLE = 'NEW_CAMPAIGN_AVAILABLE',
  NEW_MESSAGE = 'NEW_MESSAGE',
  CONNECT_CHANNEL_REMINDER = 'CONNECT_CHANNEL_REMINDER',
}

export enum CampaignRewardType {
  NONE = 'NONE',
  FIXED_MONEY_REWARD = 'FIXED_MONEY_REWARD',
  VARIABLE_MONEY_REWARD = 'VARIABLE_MONEY_REWARD',
  DYNAMIC_MONEY_REWARD = 'DYNAMIC_MONEY_REWARD',
}

export enum RecurringIntervalPrice {
  MONTH = 'MONTH',
  YEAR = 'YEAR',
}

export enum SubscriptionPriceLookupKey {
  LARGE_MONTHLY = 'LARGE_MONTHLY',
  LARGE_YEARLY = 'LARGE_YEARLY',
  MEDIUM_MONTHLY = 'MEDIUM_MONTHLY',
  MEDIUM_YEARLY = 'MEDIUM_YEARLY',
  SMALL_MONTHLY = 'SMALL_MONTHLY',
  SMALL_YEARLY = 'SMALL_YEARLY',
}

export enum TaskItemType {
  FREE_TEXT = 'FREE_TEXT',
  HASHTAG = 'HASHTAG',
  WEBSITE = 'WEBSITE',
}

export interface FreeTextTaskInput {
  type: TaskItemType.FREE_TEXT;
  freeTextTask: {
    description: string;
  };
}

export interface WebsiteTaskInput {
  type: TaskItemType.WEBSITE;
  websiteTask: {
    url: string;
  };
}

export interface HashtagTaskInput {
  type: TaskItemType.HASHTAG;
  hashtagTask: {
    hashtags: string[];
  };
}

export type TaskItemInput =
  | FreeTextTaskInput
  | WebsiteTaskInput
  | HashtagTaskInput;

export interface FreeTextTask {
  __typename: 'FreeTextTask';
  id: string;
  description: string;
}

export interface WebsiteTask {
  __typename: 'WebsiteTask';
  id: string;
  url: string;
}

export interface HashtagTask {
  __typename: 'HashtagTask';
  id: string;
  hashtags: string[];
}

export type Taskable = FreeTextTask | WebsiteTask | HashtagTask;

export enum CreatorNotificationChannel {
  EMAIL = 'EMAIL',
  PUSH_NOTIFICATION = 'PUSH_NOTIFICATION',
}

export interface Category {
  id: string;
  name: string;
}

export enum CampaignStatus {
  DRAFT = 'DRAFT',
  ACTIVE = 'ACTIVE',
  COMPLETED = 'COMPLETED',
}

export enum InstagramMediaType {
  CAROUSEL_ALBUM = 'CAROUSEL_ALBUM',
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export enum InstagramMediaProductType {
  AD = 'AD',
  FEED = 'FEED',
  REELS = 'REELS',
  STORY = 'STORY',
}

export enum MessageSender {
  CREATOR = 'CREATOR',
  BRAND = 'BRAND',
}

export enum InstagramContentType {
  REEL = 'REEL',
  POST = 'POST',
  STORY = 'STORY',
}

export enum YoutubeContentType {
  VIDEO = 'VIDEO',
}

export enum TiktokContentType {
  VIDEO = 'VIDEO',
}

export interface InstagramCampaign {
  __typename: 'InstagramCampaign';
  channelType: ChannelType;
  instagramContentType: InstagramContentType;
}

export interface YoutubeCampaign {
  __typename: 'YoutubeCampaign';
  channelType: ChannelType;
  youtubeContentType: YoutubeContentType;
}

export interface TiktokCampaign {
  __typename: 'TiktokCampaign';
  channelType: ChannelType;
  tiktokContentType: TiktokContentType;
}

export type CampaignTypeable =
  | InstagramCampaign
  | YoutubeCampaign
  | TiktokCampaign;
