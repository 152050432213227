import {
  Button,
  Card,
  CardContent,
  Chip,
  Divider,
  Typography,
  Unstable_Grid2 as Grid,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSuspenseQuery } from '@apollo/client';
import { dashboardCampaignsDocument } from '@social-garden/api/documents/campaign.ts';
import { CampaignStatus } from '@social-garden/utils/types.ts';
import BlogPostItem from '../../components/BlogPostItem.tsx';
import CampaignListItem from '../../components/CampaignListItem.tsx';

export default function DashboardContent() {
  const { t } = useTranslation(['manager']);
  const { data } = useSuspenseQuery(dashboardCampaignsDocument, {
    variables: {
      input: {
        brandIds: [],
        status: [CampaignStatus.DRAFT, CampaignStatus.ACTIVE],
      },
      first: 4,
      page: 1,
    },
  });

  if (data.campaigns.data.length === 0) {
    return (
      <Grid container spacing={4}>
        <Grid xs={12}>
          <Card variant="outlined">
            <CardContent
              sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                gap: 3,
                minHeight: 300,
              }}>
              <Typography
                variant="h5"
                align="center"
                fontWeight={300}
                color="text.secondary">
                {t('dashboard.createCampaignHeader')}
              </Typography>
              <Button
                href="/create-campaign"
                variant="contained"
                size="large"
                color="secondary">
                {t('dashboard.createCampaignButton')}
              </Button>
            </CardContent>
          </Card>
        </Grid>
        <Grid xs={12}>
          <Divider>
            <Chip label={t('dashboard.howToDivider')} variant="outlined" />
          </Divider>
        </Grid>
        <Grid container xs={12}>
          <Grid xs={12} md={4}>
            <BlogPostItem
              href="https://social.garden/blog/authentischer-und-effektiver-content"
              image="https://social.garden/wp-content/uploads/2023/09/Rectangle-13.png"
              title="Lokale Influencer: Authentischer und effektiver Content"
              content="Influencer Marketing ist längst keine Neuheit mehr, aber es bleibt eine äußerst wirksame Methode, um deine Botschaft an ein engagiertes Publikum zu verbreiten. Die Wahl lokaler Influencer kann jedoch eine noch effektivere Herangehensweise sein."
            />
          </Grid>
          <Grid xs={12} md={4}>
            <BlogPostItem
              href="https://social.garden/blog/influencer-verguetungen"
              image="https://social.garden/wp-content/uploads/2023/09/carl-heyerdahl-KE0nC8-58MQ-unsplash.jpg"
              title="Influencer Vergütungen – Produktmuster, Honorare und Kommissionen"
              content="In der dynamischen Welt des Influencer-Marketings sind Influencer Vergütungen ein entscheidender Faktor für den Erfolg deiner Kampagnen. Die richtige Vergütungsstrategie kann den Unterschied zwischen einer erfolgreichen Zusammenarbeit und einem verpassten Potenzial ausmachen."
            />
          </Grid>
          <Grid xs={12} md={4}>
            <BlogPostItem
              href="https://social.garden/blog/social-garden-effektive-kampagnen-briefings"
              image="https://social.garden/wp-content/uploads/2023/08/melanie-deziel-U33fHryBYBU-unsplash.jpg"
              title="Influencer Marketing: Effektive Kampagnen Briefings in 5 Schritten"
              content="Ein gut strukturiertes Kampagnen Briefing ist das Rückgrat jeder erfolgreichen Influencer-Marketing-Kampagne. Es dient als Kommunikationsmittel zwischen dir als Marke und den Influencern, die deine Botschaft verbreiten werden."
            />
          </Grid>
        </Grid>
      </Grid>
    );
  }

  return (
    <Grid container spacing={4}>
      <Grid container xs={12} alignItems="center">
        <Grid xs={12} md>
          <Typography variant="h5">
            {t('dashboard.latestCampaignsHeader')}
          </Typography>
        </Grid>
        <Grid xs={12} md="auto">
          <Button href="/create-campaign" variant="contained" color="secondary">
            {t('dashboard.createCampaignButton')}
          </Button>
        </Grid>
      </Grid>
      {data.campaigns.data.map((campaign) => (
        <Grid key={campaign.id} xs={12} md={6}>
          <CampaignListItem data={campaign} variant="outlined" />
        </Grid>
      ))}
      <Grid xs={12} textAlign="center">
        <Button variant="outlined" href="/campaigns">
          {t('dashboard.allCampaignsButton')}
        </Button>
      </Grid>
    </Grid>
  );
}
