import { Button, Unstable_Grid2 as Grid } from '@mui/material';
import { GridBaseProps } from '@mui/system/Unstable_Grid/GridProps';
import { ReactElement } from 'react';
import { useTranslation } from 'react-i18next';
import useInfiniteScrollPagination, {
  Paginator,
} from '../hooks/useInfiniteScrollPagination.ts';

interface GridListProps<T>
  extends Omit<GridBaseProps, 'direction' | 'columns' | 'children'> {
  paginator: Paginator<T>;
  fetchMore(options: { variables: { page: number } }): Promise<any>;
  renderItem(item: T, index: number): ReactElement;
  loading?: boolean;
  ListEmptyComponent?: ReactElement | null;
}

export default function GridList<T extends { id: string }>({
  paginator,
  fetchMore,
  renderItem,
  loading,
  ListEmptyComponent,
  ...rest
}: GridListProps<T>) {
  const { t } = useTranslation(['manager']);

  const { next } = useInfiniteScrollPagination({
    paginator,
    fetchMore,
  });

  if (paginator.data.length === 0 && ListEmptyComponent) {
    return (
      <Grid {...rest}>
        <Grid xs={12}>{ListEmptyComponent}</Grid>
      </Grid>
    );
  }
  return (
    <Grid {...rest}>
      {paginator.data.map((item, index) => renderItem(item, index))}
      {paginator.paginatorInfo.hasMorePages ? (
        <Grid
          xs={12}
          textAlign="center"
          alignItems="center"
          justifyContent="center">
          <Button variant="text" disabled={loading} onClick={next}>
            {t('manager:list.loadMoreButton')}
          </Button>
        </Grid>
      ) : null}
    </Grid>
  );
}
