import {
  Card,
  CardActionArea,
  CardContent,
  Chip,
  Unstable_Grid2 as Grid,
  Typography,
  Tooltip,
} from '@mui/material';
import { convertCentToEuro } from '@social-garden/utils/helpers.ts';
import {
  CreatorHasCampaignStatus,
  Download,
} from '@social-garden/utils/types.ts';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBullhorn } from '@fortawesome/pro-light-svg-icons/faBullhorn';
import { faTriangleExclamation } from '@fortawesome/pro-regular-svg-icons/faTriangleExclamation';
import { ORANGE } from '@social-garden/utils/colors.ts';
import ProfilePicture from './ProfilePicture.tsx';
import ChannelStatistics, {
  ChannelStatisticsData,
} from './ChannelStatistics.tsx';
import useCreatorHasCampaignStatus from '../hooks/useCreatorHasCampaignStatus.ts';

export interface CreatorHasCampaignListItemData {
  id: string;
  creator: {
    username: string;
    isUnreliable: boolean;
    profilePicture?: Download | null;
  };
  channel?: {
    channelable: ChannelStatisticsData;
  } | null;
  status: CreatorHasCampaignStatus;
  price: number;
  campaign: {
    statistics?: {
      reviewApplicationBefore?: string | null;
      reviewSubmissionBefore?: string | null;
    } | null;
  };
}

interface CreatorHasCampaignListItemProps {
  data: CreatorHasCampaignListItemData;
}

export default function CreatorHasCampaignListItem({
  data,
}: CreatorHasCampaignListItemProps) {
  const {
    t,
    i18n: { resolvedLanguage },
  } = useTranslation(['manager']);

  const { getCreatorHasCampaignStatusName, getCreatorHasCampaignStatusColor } =
    useCreatorHasCampaignStatus();

  return (
    <Card>
      <CardActionArea href={`/creator-has-campaign/${data.id}`}>
        <CardContent>
          <Grid container spacing={2} alignItems="center">
            <Grid container xs={12} md={6} lg={4} alignItems="center">
              <Grid xs="auto">
                <ProfilePicture
                  src={data.creator.profilePicture}
                  username={data.creator.username}
                  size={65}
                />
              </Grid>
              <Grid xs>
                <Typography
                  overflow="hidden"
                  textOverflow="ellipsis"
                  fontSize={20}>
                  {data.creator.username}
                </Typography>
              </Grid>
              {data.creator.isUnreliable ? (
                <Grid xs="auto">
                  <Tooltip
                    title={t('manager:creator.isUnreliable')}
                    arrow
                    disableInteractive>
                    <FontAwesomeIcon
                      icon={faTriangleExclamation}
                      color={ORANGE['500']}
                      size="xl"
                    />
                  </Tooltip>
                </Grid>
              ) : null}
            </Grid>
            {data.channel ? (
              <Grid
                sx={{
                  justifyContent: {
                    xs: 'left',
                    md: 'right',
                    lg: 'center',
                  },
                }}
                display="flex"
                xs={12}
                md={6}
                lg={4}>
                <ChannelStatistics
                  variant="simple"
                  data={data.channel.channelable}
                />
              </Grid>
            ) : (
              <Grid
                sx={{
                  textAlign: {
                    xs: 'left',
                    md: 'right',
                    lg: 'center',
                  },
                }}
                container
                xs={12}
                md={6}
                lg={4}
                spacing={0.5}>
                <Grid xs={12} textAlign="center">
                  <FontAwesomeIcon icon={faBullhorn} size="xl" />
                </Grid>
                <Grid xs={12} textAlign="center">
                  <Typography fontWeight={300} fontSize={18}>
                    {t('manager:channel.missing')}
                  </Typography>
                </Grid>
              </Grid>
            )}
            <Grid
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'left',
                  lg: 'center',
                },
              }}
              container
              xs={12}
              md={6}
              lg={2}
              spacing={{
                xs: 2,
                lg: 0.5,
              }}
              alignItems="center">
              <Grid xs="auto" lg={12}>
                <Chip
                  label={getCreatorHasCampaignStatusName(data.status)}
                  color={getCreatorHasCampaignStatusColor(data.status)}
                  variant="filled"
                />
              </Grid>
              <Grid xs lg={12}>
                {data.status === CreatorHasCampaignStatus.APPLIED &&
                data.campaign.statistics?.reviewApplicationBefore ? (
                  <Typography variant="caption">
                    {t('manager:campaign.reviewBefore', {
                      date: new Date(
                        data.campaign.statistics.reviewApplicationBefore,
                      ).toLocaleString(resolvedLanguage, {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      }),
                    })}
                  </Typography>
                ) : null}
                {data.status === CreatorHasCampaignStatus.SUBMITTED &&
                data.campaign.statistics?.reviewSubmissionBefore ? (
                  <Typography variant="caption">
                    {t('manager:campaign.reviewBefore', {
                      date: new Date(
                        data.campaign.statistics.reviewSubmissionBefore,
                      ).toLocaleString(resolvedLanguage, {
                        day: '2-digit',
                        month: '2-digit',
                        year: 'numeric',
                      }),
                    })}
                  </Typography>
                ) : null}
              </Grid>
            </Grid>
            <Grid
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'right',
                },
              }}
              xs={12}
              md={6}
              lg={2}>
              <Typography variant="h5">
                {convertCentToEuro(data.price, resolvedLanguage)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </CardActionArea>
    </Card>
  );
}
