import { Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import Section from '../../components/Section.tsx';
import ChannelStatistics, {
  ChannelStatisticsData,
} from '../../components/ChannelStatistics.tsx';
import PriceSummary from '../../components/PriceSummary.tsx';

export interface CampaignApplicationProps {
  creatorHasCampaign: {
    rewardValue?: number | null;
    price: number;
    channel?: {
      channelable: ChannelStatisticsData;
    } | null;
    answers: {
      id: string;
      answer: string;
      question: {
        question: string;
      };
    }[];
    campaignGiftCodes: {
      id: string;
      code: string;
      gift: {
        name: string;
      };
    }[];
  };
}

export default function CampaignApplication({
  creatorHasCampaign,
}: CampaignApplicationProps) {
  const { t } = useTranslation(['common', 'manager']);

  return (
    <Stack spacing={2}>
      {creatorHasCampaign.channel ? (
        <ChannelStatistics data={creatorHasCampaign.channel.channelable} />
      ) : null}
      {creatorHasCampaign.answers.length > 0 ? (
        <Section
          header={t('manager:campaignApplication.questionsAndAnswers.label')}
          spacing={1}>
          {creatorHasCampaign.answers.map(({ id, question, answer }, index) => (
            <Stack key={id}>
              <Typography variant="subtitle2">
                {`${index + 1}. ${question.question}`}
              </Typography>
              <Typography>{answer}</Typography>
            </Stack>
          ))}
        </Section>
      ) : null}
      {creatorHasCampaign.campaignGiftCodes.length > 0 ? (
        <Section
          header={t('manager:campaign.form.section.gift.header')}
          spacing={1}>
          {creatorHasCampaign.campaignGiftCodes.map(({ id, gift, code }) => (
            <Stack key={id}>
              <Typography variant="subtitle2">{gift.name}</Typography>
              <Typography>{code}</Typography>
            </Stack>
          ))}
        </Section>
      ) : null}
      <PriceSummary
        price={creatorHasCampaign.price}
        rewardValue={creatorHasCampaign.rewardValue}
      />
    </Stack>
  );
}
